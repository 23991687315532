<template>
  <div class="row items-center justify-between">
    <div class="col text-caption text-left">
      {{ data.message }}
    </div>

    <q-space />

    <div>
      <q-btn
        color="dark"
        icon="link"
        @click="handleRoute"
        round
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapMutations } from 'vuex'

// Quasar
import { format } from 'quasar'

export default {
  name: 'LinkMessage',
  props: {
    data: {
      type: Object,
      default () {
        return { message: '' }
      }
    }
  },
  methods: {
    ...mapMutations([
      'addRouteToShortcuts'
    ]),
    getName (route) {
      const paths = route.split('/')
      const path = paths[paths.length - 1]

      if (!path) {
        return 'OrderAdmin'
      }

      const newPath = path.split('?')[0]

      if (!isNaN(Number(newPath))) {
        return format.capitalize(`${paths[paths.length - 2].replace('-', ' ')} - ${newPath}`)
      }

      return format.capitalize(newPath.replace('-', ' '))
    },
    handleRoute () {
      const data = {
        route: this.$route.fullPath,
        name: this.getName(this.$route.fullPath)
      }

      this.addRouteToShortcuts(data)

      this.$router.push(this.data.link)
    }
  }
}
</script>
