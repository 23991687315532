<template>
  <div class="text-right">
    <q-btn
      v-morph="{ group: 'placeHead', name: 'btn', model: morphGroupModel, duration: 300, resize: true }"
      class="shadow"
      :style="hover ? 'morph-expand' : ''"
      fab
      color="warning"
      @mouseover="setHover(true)"
      @mouseleave="setHover(false)"
      @click="nextMorph"
    >
      <q-badge
        color="danger"
        floating
        @click="removeAllStoragePlaceEvents"
      >
        X
      </q-badge>

      <div class="row items-center">
        <h6
          v-if="hover"
          class="q-my-none text-caption q-mr-sm"
        >
          {{ $t('Opened place') }}
        </h6>

        <q-icon name="room" />
      </div>
    </q-btn>

    <q-card
      v-morph="{ group: 'placeHead', name: 'card', model: morphGroupModel, duration: 300, resize: true }"
      class="absolute-bottom-right q-ma-md bg-teal text-white"
      style="width: 340px; border-bottom-left-radius: 2em; z-index: 2;"
    >
      <q-card-section class="row items-center justify-between">
        <h6 class="q-my-none">
          {{ $t('Opened place') }}
        </h6>

        <q-btn
          text-color="white"
          size="sm"
          icon="close"
          no-caps
          flat
          unelevated
          @click="nextMorph"
        />
      </q-card-section>

      <q-card-section
        v-if="item"
        class="bg-white text-dark"
      >
        <new-picking-place
          v-if="item.data && item.data.place"
          :data="item.data.place"
          :force-id="true"
        />

        <div
          v-else
          class="text-caption text-center"
        >
          {{ $t('No data about place') }}
        </div>

        <div
          v-if="item.data"
          class="text-caption q-mt-md text-center"
        >
          {{ `${$t('Opened by')}: ${item.data.email} (${item.data.user})` }}
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import NewPickingPlace from '../picking/new-picking-place.vue'

export default {
  name: 'PlaceHead',
  components: {
    NewPickingPlace
  },
  data () {
    return {
      nextMorphStep: {
        btn: 'card',
        card: 'btn'
      },
      morphGroupModel: 'btn',
      hover: false
    }
  },
  computed: {
    ...mapGetters([
      'storagePlaceEvents'
    ]),
    item () {
      return this.storagePlaceEvents[this.storagePlaceEvents.length - 1]
    }
  },
  methods: {
    ...mapMutations([
      'removeStoragePlaceEvent',
      'removeAllStoragePlaceEvents'
    ]),
    setHover (isHover) {
      this.hover = isHover
    },
    handleRemove (entity) {
      this.$channels.user.publish('closePlace', entity)
      this.removeStoragePlaceEvent(entity)
    },
    nextMorph () {
      this.morphGroupModel = this.nextMorphStep[this.morphGroupModel]
    }
  }
}
</script>
