<template>
  <div class="text-right relative">
    <div
      v-if="lastHelper && lastHelper.state === 'active'"
      class="helper-message row items-center justify-end"
    >
      <div class="bg-amber rounded q-py-sm q-px-md text-caption">
        <messages :data="lastHelper" />
      </div>
    </div>

    <q-btn
      v-morph="{ group: 'helperHead', name: 'btn', model: morphGroupModel, duration: 300, resize: true }"
      class="shadow"
      :style="hover ? 'morph-expand' : ''"
      fab
      color="dark"
      @mouseover="setHover(true)"
      @mouseleave="setHover(false)"
      @click="nextMorph"
    >
      <q-badge
        color="danger"
        floating
        @click="handleDelete"
      >
        X
      </q-badge>

      <div class="row items-center">
        <h6
          v-if="hover"
          class="q-my-none text-caption q-mr-sm"
        >
          {{ $t('Helper') }}
        </h6>

        <q-icon name="lightbulb" color="amber" />
      </div>
    </q-btn>

    <q-card
      v-morph="{ group: 'helperHead', name: 'card', model: morphGroupModel, duration: 300, resize: true }"
      class="absolute-bottom-right q-ma-md bg-teal text-white"
      style="width: 340px; border-bottom-left-radius: 2em; z-index: 2;"
    >
      <q-card-section class="row items-center justify-between">
        <h6 class="q-my-none">
          {{ $t('Helper') }}
        </h6>

        <q-btn
          text-color="white"
          size="sm"
          icon="close"
          no-caps
          flat
          unelevated
          @click="nextMorph"
        />
      </q-card-section>

      <q-card-section class="bg-white text-dark">
        <q-list
          dense
          bordered
          padding
        >
          <q-item
            v-for="helper in helpers"
            :key="helper.id"
            clickable
            v-ripple
            class="block"
          >
            <messages :data="helper" />
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import Messages from './Messages.vue'

export default {
  name: 'HelperHead',
  components: {
    Messages
  },
  data () {
    return {
      nextMorphStep: {
        btn: 'card',
        card: 'btn'
      },
      morphGroupModel: 'btn',
      hover: false
    }
  },
  computed: {
    ...mapGetters([
      'lastHelper',
      'helpers'
    ])
  },
  methods: {
    ...mapMutations([
      'deleteAllHelpers'
    ]),
    handleDelete () {
      this.deleteAllHelpers()
    },
    setHover (isHover) {
      this.hover = isHover
    },
    nextMorph () {
      this.morphGroupModel = this.nextMorphStep[this.morphGroupModel]
    }
  }
}
</script>

<style scoped>
.helper-message {
  position: absolute;
  top: 0;
  right: 65px;
  max-width: 300px;
  min-width: 240px;
  height: 60px;
  overflow: auto;
  text-align: left;
  animation: fade .3s ease-in-out;
}

@media screen and (max-width: 599px) {
  .helper-message {
    max-width: 220px;
    min-width: 160px;
  }
}
</style>
