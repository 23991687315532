<template>
  <div class="text-right">
    <q-btn
      v-morph="{ group: 'rejectedHead', name: 'btn', model: morphGroupModel, duration: 300, resize: true }"
      class="shadow"
      :style="hover ? 'morph-expand' : ''"
      fab
      color="negative"
      @mouseover="setHover(true)"
      @mouseleave="setHover(false)"
      @click="nextMorph"
    >
      <q-badge
        color="success"
        style="right: unset; left: -3px;"
        floating
      >
        {{ rejectedTasks.length }}
      </q-badge>

      <q-badge
        color="danger"
        floating
        @click="removeAllRejectedTasks"
      >
        X
      </q-badge>

      <div class="row items-center">
        <h6
          v-if="hover"
          class="q-my-none text-caption q-mr-sm"
        >
          {{ $t('Rejected Tasks') }}
        </h6>

        <q-icon name="notification_important" />
      </div>
    </q-btn>

    <q-card
      v-morph="{ group: 'rejectedHead', name: 'card', model: morphGroupModel, duration: 300, resize: true }"
      class="absolute-bottom-right q-ma-md bg-teal text-white"
      style="width: 340px; border-bottom-left-radius: 2em; z-index: 2;"
    >
      <q-card-section class="row items-center justify-between">
        <h6 class="q-my-none">
          {{ $t('Rejected Tasks') }}
        </h6>

        <q-btn
          text-color="white"
          size="sm"
          icon="close"
          no-caps
          flat
          unelevated
          @click="nextMorph"
        />
      </q-card-section>

      <q-card-section class="bg-white text-dark">
        <q-list
          bordered
          separator
        >
          <q-item
            v-for="(item, i) in rejectedTasks"
            :key="`${item.entity}:${i}`"
            v-ripple
            class="row items-center"
          >
            <q-item-section class="text-subtitle1 text-left">
              {{ `${$t('Task')}: ${item.entity}` }}
            </q-item-section>

            <q-btn
              color="negative"
              icon="delete"
              round
              @click="handleRemove(item.entity)"
            />
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RejectedTasksHead',
  data () {
    return {
      nextMorphStep: {
        btn: 'card',
        card: 'btn'
      },
      morphGroupModel: 'btn',
      hover: false
    }
  },
  computed: {
    ...mapGetters([
      'rejectedTasks'
    ])
  },
  methods: {
    ...mapMutations([
      'removeRejectedTasks',
      'removeAllRejectedTasks'
    ]),
    setHover (isHover) {
      this.hover = isHover
    },
    handleRemove (entity) {
      this.removeRejectedTasks(entity)
    },
    nextMorph () {
      this.morphGroupModel = this.nextMorphStep[this.morphGroupModel]
    }
  }
}
</script>
