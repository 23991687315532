<template>
  <span>
    {{ data.message }}
  </span>
</template>

<script>
export default {
  name: 'Message',
  props: {
    data: {
      type: Object,
      default () {
        return { message: '' }
      }
    }
  }
}
</script>
