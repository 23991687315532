<template>
  <div class="text-right">
    <q-btn
      v-morph="{ group: id, name: 'btn', model: morphGroupModel, duration: 300, resize: true }"
      class="shadow"
      :style="hover ? 'morph-expand' : ''"
      fab
      color="success"
      @mouseover="setHover(true)"
      @mouseleave="setHover(false)"
      @click="nextMorph"
    >
      <q-badge
        v-if="messagesCount > 0"
        color="orange"
        style="right: unset; left: -3px;"
        floating
      >
        {{ messagesCount }}
      </q-badge>

      <q-badge
        color="danger"
        floating
        @click="handleRemove"
      >
        X
      </q-badge>

      <div class="row items-center">
        <h6
          v-if="hover"
          class="q-my-none text-caption q-mr-sm"
        >
          {{ title }}
        </h6>

        <q-icon name="pets" />
      </div>
    </q-btn>

    <q-card
      v-morph="{ group: id, name: 'card', model: morphGroupModel, duration: 300, resize: true }"
      class="absolute-bottom-right q-ma-md text-white"
      style="width: 300px; border-bottom-left-radius: 2em; z-index: 2;"
    >
      <q-card-section class="row items-center justify-between bg-teal">
        <h6 class="q-my-none">
          {{ title }}
        </h6>

        <q-btn
          text-color="white"
          size="sm"
          icon="close"
          no-caps
          flat
          unelevated
          @click="nextMorph"
        />
      </q-card-section>

      <q-card-section class="text-dark">
        <chat
          ref="chat"
          :height="70"
          :entities="entity"
        />
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Component
import Chat from './Chat'

import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'ChatHead',
  components: {
    Chat
  },
  props: {
    entities: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      id: uuidv4(),
      nextMorphStep: {
        btn: 'card',
        card: 'btn'
      },
      morphGroupModel: 'btn',
      messagesCount: 0,
      lastMessageCount: 0,
      hover: false
    }
  },
  computed: {
    ...mapGetters([
      'allMessages',
      'getChatMessages'
    ]),
    title () {
      if (this.entities['Orderadmin\\Products\\Entity\\AbstractOrder']) {
        return this.$t('Order') + ': ' + this.entities['Orderadmin\\Products\\Entity\\AbstractOrder']
      }

      if (this.entities['Orderadmin\\Products\\Entity\\Shop']) {
        return this.$t('Store') + ': ' + this.entities['Orderadmin\\Products\\Entity\\Shop']
      }

      if (this.entities['Orderadmin\\Storage\\Entity\\Movement\\Acceptance']) {
        return this.$t('Acceptance') + ': ' + this.entities['Orderadmin\\Storage\\Entity\\Movement\\Acceptance']
      }

      if (this.entities['Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest']) {
        return this.$t('Shipping Request') + ': ' + this.entities['Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest']
      }

      return 'Title'
    },
    entity () {
      return Object.keys(this.entities).reduce((acc, entityClass) => {
        acc.push({ entityClass, entity: this.entities[entityClass] })
        return acc
      }, [])
    }
  },
  watch: {
    allMessages () {
      this.calcNewMessages()
    }
  },
  mounted () {
    this.calcNewMessages()
  },
  methods: {
    ...mapMutations([
      'deleteMessagesByEntity'
    ]),
    setHover (isHover) {
      this.hover = isHover
    },
    calcNewMessages () {
      const userdata = JSON.parse(localStorage.getItem('userData'))

      this.messagesCount = this.getChatMessages(this.entities, (message) => message.author !== userdata.id).length - this.lastMessageCount
    },
    handleRemove () {
      return this.deleteMessagesByEntity(this.entities)
    },
    nextMorph () {
      this.morphGroupModel = this.nextMorphStep[this.morphGroupModel]

      if (this.morphGroupModel === 'card') {
        this.$refs.chat.focusOnLastMessage()
      }

      this.lastMessageCount = this.messagesCount
      this.messagesCount = 0
    }
  }
}
</script>
