<template>
  <div
    :style="`position: fixed; z-index: 1200; bottom: ${isOpenSupport ? 100 : 10}px; right: 20px;`"
  >
    <chat-head
      v-for="item in chatHeads"
      :key="JSON.stringify(item)"
      :entities="item"
      class="q-mb-sm"
    />

    <place-head
      v-if="storagePlaceEvents.length > 0 && isEnabledPlaceHeadForRoute"
      class="q-mb-sm"
    />

    <rejected-tasks-head
      v-if="rejectedTasks.length > 0 && isEnabledRejectedTaskHeadForRoute"
      class="q-mb-sm"
    />

    <helper-head v-if="helpers.length > 0" />
  </div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// Components
import ChatHead from './../chat/ChatHead'
import HelperHead from './HelperHead.vue'
import PlaceHead from './PlaceHead'
import RejectedTasksHead from './RejectedTasksHead'

export default {
  name: 'Heads',
  components: {
    ChatHead,
    PlaceHead,
    RejectedTasksHead,
    HelperHead
  },
  computed: {
    ...mapGetters([
      'headsOptions',
      'chatHeads',
      'rejectedTasks',
      'storagePlaceEvents',
      'isOpenSupport',
      'helpers'
    ]),
    isEnabledPlaceHeadForRoute () {
      return !!this.headsOptions.place.routes.find(path => this.$route.path.includes(path))
    },
    isEnabledRejectedTaskHeadForRoute () {
      return !!this.headsOptions.rejectedTask.routes.find(path => this.$route.path.includes(path))
    }
  }
}
</script>
