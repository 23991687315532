<template>
  <component
    :is="components[data.type]"
    :data="data"
  />
</template>

<script>
// Components
import Message from './Message.vue'
import LinkMessage from './LinkMessage.vue'

export default {
  name: 'Messages',
  components: {
    Message,
    LinkMessage
  },
  props: {
    data: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      components: {
        link: 'link-message',
        message: 'message'
      }
    }
  }
}
</script>
